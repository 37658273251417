import { createSlice } from "@reduxjs/toolkit";

// project imports
import axiosex from "axios";
import { dispatch } from "../index";

// ----------------------------------------------------------------------

const initialState = {
  error: null,
  allProvider: [],
  rankPromoteMonthly: [],
  rankPromote: [],
  sumReceiveMonthly: [],
  sumReceive: [],
  allRankingHistory: [],
  positionSummary: [],
  allRankingHistoryByID: [],
  allRanking: [],
  playerCash: [],
  outstandingByID: [],
  freespinByID: [],
  playHistoryByID: [],
  creditHistory: [],
  creditHistoryByID: [],
  withdrawHistoryByID: [],
  withdrawDetailByID: [],
  openBoxSetting: [],
  allBanner: [],
  allCampaign: [],
  campaignByID: [],
  idBanner: [],
  idPopup: [],
  allPopup: [],
  adminHistoryData: [],
  transactionDepositSummary: [],
  transactionWithdrawSummary: [],
  TMNTransactionHistory: [],
  BANKTransactionHistory: [],
  SMSTransactionHistory: [],
  SMSBalance: [],
  GSBTransactionHistory: [],
  userAvailablePromotion: [],
  playerLoginLink: [],
  playerBalance: [],
  todayPromotionReceived: [],
  allPromotionReceived: [],
  userPlayHistory: [],
  bankList: [],
  transferBankList: [],
  tmnTransferList: [],
  withdrawHistory: [],
  transactionDepositHistory: [],
  withdrawrequestlist: [],
  newUserDepositDaily: [],
  sumAllWithdrawDaily: [],
  sumAllDepositDaily: [],
  sumAllWithdrawMonthly: [],
  sumAllDepositMonthly: [],
  sumAllWithdrawYearly: [],
  sumAllDepositYearly: [],
  sumAllBonusDaily: [],
  sumAllBonusMonthly: [],
  depositHistoryByID: [],
  betflixStatus: [],
  inviteSetting: [],
  tmnSetting: [],
  wheelHistory: [],
  playerWheelHistory: [],
  wheelSetting: [],
  scbSetting: [],
  gsbSetting: [],
  websiteSetting: [],
  tmnData: [],
  scbData: [],
  gsbData: [],
  transactionHistory: [],
  cashbackSetting: [],
  lineSetting: [],
  withdrawSetting: [],
  totalUsers: [],
  singleUsers: [],
  agentInfo: [],
  agentฺBalance: [],
  singleAdmin: [],
  singlePromo: [],
  allPromo: [],
  singleCreditCode: [],
  allCreditCode: [],
  usedCreditCode: [],
  unusedCreditCode: [],
  todayUsers: [],
  monthUsers: [],
  byIdTransDW: [],
  allTransDW: [],
  allAdmin: [],
  usersS1: [],
  usersS2: [],
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    getAllProviderSuccess(state, action) {
      state.allProvider = action.payload;
    },

    rankPromoteMonthlySuccess(state, action) {
      state.rankPromoteMonthly = action.payload;
    },
    rankPromoteSuccess(state, action) {
      state.rankPromote = action.payload;
    },
    sumReceiveMonthlySuccess(state, action) {
      state.sumReceiveMonthly = action.payload;
    },
    sumReceiveSuccess(state, action) {
      state.sumReceive = action.payload;
    },

    getPositionSummarySuccess(state, action) {
      state.positionSummary = action.payload;
    },

    getAllRankingHistorySuccess(state, action) {
      state.allRankingHistory = action.payload;
    },

    getAllRankingHistoryByIDSuccess(state, action) {
      state.allRankingHistoryByID = action.payload;
    },

    getAllRankingSuccess(state, action) {
      state.allRanking = action.payload;
    },

    getPlayerCashSuccess(state, action) {
      state.playerCash = action.payload;
    },

    getOutstandingByIDSuccess(state, action) {
      state.outstandingByID = action.payload;
    },

    getFreespinByIDSuccess(state, action) {
      state.freespinByID = action.payload;
    },

    getPlayHistoryByIDSuccess(state, action) {
      state.playHistoryByID = action.payload;
    },

    getCreditHistorySuccess(state, action) {
      state.creditHistory = action.payload;
    },

    getCreditHistoryByIDSuccess(state, action) {
      state.creditHistoryByID = action.payload;
    },

    getWithdrawHistoryByIDSuccess(state, action) {
      state.withdrawHistoryByID = action.payload;
    },

    getWithdrawDetailByIDSuccess(state, action) {
      state.withdrawDetailByID = action.payload;
    },

    getAllCampaignSuccess(state, action) {
      state.allCampaign = action.payload;
    },

    getCampaignByIDSuccess(state, action) {
      state.campaignByID = action.payload;
    },

    getAllOpenBoxSettingSuccess(state, action) {
      state.openBoxSetting = action.payload;
    },

    getAllBannerSuccess(state, action) {
      state.allBanner = action.payload;
    },

    getBannerByIDSuccess(state, action) {
      state.idBanner = action.payload;
    },

    getAllPopupSuccess(state, action) {
      state.allPopup = action.payload;
    },

    getPopupByIDSuccess(state, action) {
      state.idPopup = action.payload;
    },

    getAdminHistoryDataSuccess(state, action) {
      state.adminHistoryData = action.payload;
    },

    getTransactionDepositSummarySuccess(state, action) {
      state.transactionDepositSummary = action.payload;
    },

    getTransactionWithdrawSummarySuccess(state, action) {
      state.transactionWithdrawSummary = action.payload;
    },

    getTMNTransactionHistorySuccess(state, action) {
      state.TMNTransactionHistory = action.payload;
    },

    getBANKTransactionHistorySuccess(state, action) {
      state.BANKTransactionHistory = action.payload;
    },

    getSMSTransactionHistorySuccess(state, action) {
      state.SMSTransactionHistory = action.payload;
    },

    getSMSBalanceSuccess(state, action) {
      state.SMSBalance = action.payload;
    },

    getGSBTransactionHistorySuccess(state, action) {
      state.GSBTransactionHistory = action.payload;
    },

    getUserAvailablePromotionSuccess(state, action) {
      state.userAvailablePromotion = action.payload;
    },

    getPlayerLoginLinkSuccess(state, action) {
      state.playerLoginLink = action.payload;
    },

    getPlayerBalanceSuccess(state, action) {
      state.playerBalance = action.payload;
    },

    getTodayPromotionReceivedSuccess(state, action) {
      state.todayPromotionReceived = action.payload;
    },

    getAllPromotionReceivedSuccess(state, action) {
      state.allPromotionReceived = action.payload;
    },

    getUserPlayHistorySuccess(state, action) {
      state.userPlayHistory = action.payload;
    },

    getNewUserDepositDailySuccess(state, action) {
      state.newUserDepositDaily = action.payload;
    },

    getSumAllWithdrawDailySuccess(state, action) {
      state.sumAllWithdrawDaily = action.payload;
    },

    getTMNTransferListSuccess(state, action) {
      state.tmnTransferList = action.payload;
    },

    getSumAllDepositDailySuccess(state, action) {
      state.sumAllDepositDaily = action.payload;
    },

    getSumAllWithdrawMonthlySuccess(state, action) {
      state.sumAllWithdrawMonthly = action.payload;
    },

    getSumAllDepositMonthlySuccess(state, action) {
      state.sumAllDepositMonthly = action.payload;
    },

    getSumAllWithdrawYearlySuccess(state, action) {
      state.sumAllWithdrawYearly = action.payload;
    },

    getSumAllDepositYearlySuccess(state, action) {
      state.sumAllDepositYearly = action.payload;
    },

    getSumAllBonusDailySuccess(state, action) {
      state.sumAllBonusDaily = action.payload;
    },

    getSumAllBonusMonthlySuccess(state, action) {
      state.sumAllBonusMonthly = action.payload;
    },

    getWithdrawHistorySuccess(state, action) {
      state.withdrawHistory = action.payload;
    },
    // GET USERS STYLE 1
    getTransactionHistorySuccess(state, action) {
      state.transactionHistory = action.payload;
    },

    getTransactionDepositHistorySuccess(state, action) {
      state.transactionDepositHistory = action.payload;
    },

    getWithdrawrequestlistSuccess(state, action) {
      state.withdrawrequestlist = action.payload;
    },

    getDepositHistoryByIDSuccess(state, action) {
      state.depositHistoryByID = action.payload;
    },

    getBetflixStatus(state, action) {
      state.betflixStatus = action.payload;
    },

    getBankListSuccess(state, action) {
      state.bankList = action.payload;
    },

    getTransferBankListSuccess(state, action) {
      state.transferBankList = action.payload;
    },

    getTMNSettingSuccess(state, action) {
      state.tmnSetting = action.payload;
    },

    getWheelHistorySuccess(state, action) {
      state.wheelHistory = action.payload;
    },

    getPlayerWheelHistorySuccess(state, action) {
      state.playerWheelHistory = action.payload;
    },

    getWheelSettingSuccess(state, action) {
      state.wheelSetting = action.payload;
    },

    getSCBSettingSuccess(state, action) {
      state.scbSetting = action.payload;
    },

    getGSBSettingSuccess(state, action) {
      state.gsbSetting = action.payload;
    },

    getTMNDataSuccess(state, action) {
      state.tmnData = action.payload;
    },

    getSCBDataSuccess(state, action) {
      state.scbData = action.payload;
    },

    getGSBDataSuccess(state, action) {
      state.gsbData = action.payload;
    },

    getWebsiteSettingSuccess(state, action) {
      state.websiteSetting = action.payload;
    },

    getLineSettingSuccess(state, action) {
      state.lineSetting = action.payload;
    },

    getWithdrawSettingSuccess(state, action) {
      state.withdrawSetting = action.payload;
    },

    getInviteSettingSuccess(state, action) {
      state.inviteSetting = action.payload;
    },

    getCashbackSettingSuccess(state, action) {
      state.cashbackSetting = action.payload;
    },

    getUsedCreditCodeSuccess(state, action) {
      state.usedCreditCode = action.payload;
    },

    getUnusedCreditCodeSuccess(state, action) {
      state.unusedCreditCode = action.payload;
    },

    getAgentInfoSuccess(state, action) {
      state.agentInfo = action.payload;
    },

    getAgentBalanceSuccess(state, action) {
      state.agentBalance = action.payload;
    },

    getAllAdminSuccess(state, action) {
      state.allAdmin = action.payload;
    },

    getUsersListStyle1Success(state, action) {
      state.usersS1 = action.payload;
    },

    getTotalUsersSuccess(state, action) {
      state.totalUsers = action.payload;
    },

    getPromoByIDSuccess(state, action) {
      state.singlePromo = action.payload;
    },

    getAllPromoSuccess(state, action) {
      state.allPromo = action.payload;
    },

    getCreditCodeByIDSuccess(state, action) {
      state.singleCreditCode = action.payload;
    },

    getAllCreditCodeSuccess(state, action) {
      state.allCreditCode = action.payload;
    },

    getUsersByIDSuccess(state, action) {
      state.singleUsers = action.payload;
    },

    getAdminByIDSuccess(state, action) {
      state.singleAdmin = action.payload;
    },

    getTodayUsersSuccess(state, action) {
      state.todayUsers = action.payload;
    },

    getMonthUsersSuccess(state, action) {
      state.monthUsers = action.payload;
    },

    getAllTransactionDWSuccess(state, action) {
      state.allTransDW = action.payload;
    },

    getByIdTransactionDWSuccess(state, action) {
      state.byIdTransDW = action.payload;
    },

    // GET USERS STYLE 2
    getUsersListStyle2Success(state, action) {
      state.usersS2 = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllProvider(id) {
  return async () => {
    try {
      const response = await axiosex.post(
        `https://api.s24hr.com/gamelogin/getprovider/${0}`
      );
      dispatch(slice.actions.getAllProviderSuccess(response.data.providerList));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSumReceive() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/ranking/sumreceive`
      );
      dispatch(slice.actions.sumReceiveSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSumReceiveMonthly() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/ranking/sumreceivemonthly`
      );
      dispatch(slice.actions.sumReceiveMonthlySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRankPromote() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/ranking/rankpromote`
      );
      dispatch(slice.actions.rankPromoteSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRankPromoteMonthly() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/ranking/rankpromotemonthly`
      );
      dispatch(slice.actions.rankPromoteMonthlySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPositionSummary() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/ranking/positionsummary`
      );
      dispatch(slice.actions.getPositionSummarySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllRankingHistory() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/ranking/getallrankhistory`
      );
      dispatch(slice.actions.getAllRankingHistorySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllRankingHistoryByID(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/ranking/getrankhistorybyid/${id}`
      );
      dispatch(slice.actions.getAllRankingHistoryByIDSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllRanking() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.s24hr.com/ranking`);
      dispatch(slice.actions.getAllRankingSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPlayerCash(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/userbalance/cash/${id}`
      );
      dispatch(slice.actions.getPlayerCashSuccess(response?.data?.cashWallet));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOutstandingByID(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/userbalance/checkoutstanding/${id}`
      );
      dispatch(slice.actions.getOutstandingByIDSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFreespinByID(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/userbalance/checkfreespins/${id}`
      );
      dispatch(slice.actions.getFreespinByIDSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPlayHistoryByID(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/userbalance/checkhistorygame/${id}`
      );
      dispatch(slice.actions.getPlayHistoryByIDSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCreditHistory() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/userbalance/allcredithistory`
      );
      dispatch(slice.actions.getCreditHistorySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCreditHistoryByID(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/userbalance/credithistory/${id}`
      );
      dispatch(slice.actions.getCreditHistoryByIDSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCampaignByID(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/marketingcampaign/getbyid/${id}`
      );
      dispatch(slice.actions.getCampaignByIDSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllCampaign() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/marketingcampaign/`
      );
      dispatch(slice.actions.getAllCampaignSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOpenBoxSetting() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/countdownbox/setting`
      );
      dispatch(slice.actions.getAllOpenBoxSettingSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllBanner() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/setting/banner`
      );
      dispatch(slice.actions.getAllBannerSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBannerByID(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/setting/banner/${id}`
      );
      dispatch(slice.actions.getBannerByIDSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllPopup() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.s24hr.com/setting/popup`);
      dispatch(slice.actions.getAllPopupSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPopupByID(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/setting/popup/${id}`
      );
      dispatch(slice.actions.getPopupByIDSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAdminHistoryData() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/189a1sf898f/getadminhistory`
      );
      dispatch(slice.actions.getAdminHistoryDataSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTMNTransactionHistory() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/bank/tmnhistory`
      );
      dispatch(
        slice.actions.getTMNTransactionHistorySuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBANKTransactionHistory() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/bank/bankhistory`
      );
      dispatch(
        slice.actions.getBANKTransactionHistorySuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSMSBalance() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.s24hr.com/sms/balance`);
      dispatch(slice.actions.getSMSBalanceSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSMSTransactionHistory() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/sms/smstransaction`
      );
      dispatch(
        slice.actions.getSMSTransactionHistorySuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGSBTransactionHistory() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/bank/gsbhistory`
      );
      dispatch(
        slice.actions.getGSBTransactionHistorySuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserAvailablePromotion(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/usersystem/getavailablepromotion/${id.id}`
      );
      dispatch(
        slice.actions.getUserAvailablePromotionSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPlayerLoginLink(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/gamelogin/${id.id}`
      );
      dispatch(slice.actions.getPlayerLoginLinkSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPlayerBalance(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/userbalance/balance/${id.id}`
      );
      dispatch(slice.actions.getPlayerBalanceSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTodayPromotionReceived(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/usersystem/checkreceivedpromotion/${id.id}`
      );
      dispatch(
        slice.actions.getTodayPromotionReceivedSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllPromotionReceived(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/usersystem/checkallreceivedpromotion/${id.id}`
      );
      dispatch(
        slice.actions.getAllPromotionReceivedSuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserPlayHistory(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/agentbalance/playsummary/${id}`
      );
      dispatch(
        slice.actions.getUserPlayHistorySuccess(response.data.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getWithdrawDetailByID(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/userbalance/checkwithdrawdetail/${id}`,
        {
          headers: {
            "Cache-Control":
              "no-cache, no-store, must-revalidate, must-revalidate, post-check=0, pre-check=0",
            Pragma: "no-cache",
            Expires: "0",
          },
        }
      );
      dispatch(slice.actions.getWithdrawDetailByIDSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getNewUserDepositDaily() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/8hwe8vhevv/newuserdepositdaily`
      );
      dispatch(slice.actions.getNewUserDepositDailySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSumAllWithdrawDaily() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/8hwe8vhevv/sumallwithdrawdaily`
      );
      dispatch(slice.actions.getSumAllWithdrawDailySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTMNTransferList() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/bank/tmntransferlist`
      );
      dispatch(slice.actions.getTMNTransferListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSumAllDepositDaily() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/8hwe8vhevv/sumalldepositdaily`
      );
      dispatch(slice.actions.getSumAllDepositDailySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSumAllDepositMonthly() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/8hwe8vhevv/sumalldepositmonthly`
      );
      dispatch(
        slice.actions.getSumAllDepositMonthlySuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSumAllWithdrawMonthly() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/8hwe8vhevv/sumallwithdrawmonthly`
      );
      dispatch(
        slice.actions.getSumAllWithdrawMonthlySuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSumAllWithdrawYearly() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/8hwe8vhevv/sumallwithdrawyearly`
      );
      dispatch(
        slice.actions.getSumAllWithdrawYearlySuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSumAllDepositYearly() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/8hwe8vhevv/sumalldeposityearly`
      );
      dispatch(slice.actions.getSumAllDepositYearlySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSumAllBonusDaily() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/8hwe8vhevv/sumallbonusdaily`
      );
      dispatch(slice.actions.getSumAllBonusDailySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSumAllBonusMonthly() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/8hwe8vhevv/sumallbonusmonthly`
      );
      dispatch(slice.actions.getSumAllBonusMonthlySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBetflixStatus() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.bfx.fail/v4/status`);
      dispatch(slice.actions.getBetflixStatusSuccess(response.data.status));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getWithdrawHistory() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/userbalance/allwithdraw`
      );
      dispatch(slice.actions.getWithdrawHistorySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getWithdrawHistoryByID(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/userbalance/withdrawhistory/${id}`,
        {
          headers: {
            "Cache-Control":
              "no-cache, no-store, must-revalidate, must-revalidate, post-check=0, pre-check=0",
            Pragma: "no-cache",
            Expires: "0",
          },
        }
      );
      dispatch(slice.actions.getWithdrawHistoryByIDSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDepositHistoryByID(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/userbalance/history/${id}`
      );
      dispatch(slice.actions.getDepositHistoryByIDSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getBankList() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.s24hr.com/bank`);
      dispatch(slice.actions.getBankListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTranferBankList() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/8hwe8vhevv/banklist`
      );
      dispatch(slice.actions.getTransferBankListSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTransactionHistory() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/8hwe8vhevv/history`
      );
      dispatch(slice.actions.getTransactionHistorySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getWithdrawrequestlist() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/8hwe8vhevv/withdrawrequestlist`,
        {
          headers: {
            "Cache-Control":
              "no-cache, no-store, must-revalidate, must-revalidate, post-check=0, pre-check=0",
            Pragma: "no-cache",
            Expires: "0",
          },
        }
      );
      dispatch(slice.actions.getWithdrawrequestlistSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTransactionDepositHistory() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/8hwe8vhevv/deposithistory`
      );
      dispatch(
        slice.actions.getTransactionDepositHistorySuccess(response.data.data)
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTMNData() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/8hwe8vhevv/tmninfo`
      );
      dispatch(slice.actions.getTMNDataSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSCBData() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/8hwe8vhevv/scbinfo`
      );
      dispatch(slice.actions.getSCBDataSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTMNSetting() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.s24hr.com/setting/tmn`);
      dispatch(slice.actions.getTMNSettingSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getWheelHistory() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/wheel/allhistory`
      );
      dispatch(slice.actions.getWheelHistorySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPlayerWheelHistory(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/wheel/history/${id}`
      );
      dispatch(slice.actions.getPlayerWheelHistorySuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getWheelSetting() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/setting/wheelsetting`
      );
      dispatch(slice.actions.getWheelSettingSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGSBData() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/8hwe8vhevv/gsbinfo`
      );
      dispatch(slice.actions.getGSBDataSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSCBSetting() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.s24hr.com/setting/scb`);
      dispatch(slice.actions.getSCBSettingSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGSBSetting() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.s24hr.com/setting/gsb`);
      console.log(response);
      dispatch(slice.actions.getGSBSettingSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getWebsiteSetting() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/setting/websetting`
      );
      dispatch(slice.actions.getWebsiteSettingSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCashbackSetting() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/setting/cashback`
      );
      dispatch(slice.actions.getCashbackSettingSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getLineSetting() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/setting/linesetting`
      );
      dispatch(slice.actions.getLineSettingSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getWithdrawSetting() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/setting/withdraw`
      );
      dispatch(slice.actions.getWithdrawSettingSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUsedCreditCode() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/creditcode/used`
      );
      dispatch(slice.actions.getUsedCreditCodeSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getInviteSetting() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/setting/invite`
      );
      dispatch(slice.actions.getInviteSettingSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUnusedCreditCode() {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/creditcode/unused`
      );
      dispatch(slice.actions.getUnusedCreditCodeSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllPromo() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.s24hr.com/promotion`);
      dispatch(slice.actions.getAllPromoSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAgentInfo() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.s24hr.com/agentinfo`);
      dispatch(slice.actions.getAgentInfoSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAgentBalance() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.s24hr.com/agentbalance`);
      dispatch(slice.actions.getAgentBalanceSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPromoByID(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/promotion/getbyid/${id}`
      );
      dispatch(slice.actions.getPromoByIDSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllCreditCode() {
  return async () => {
    try {
      const response = await axiosex.get(`https://api.s24hr.com/creditcode`);
      dispatch(slice.actions.getAllCreditCodeSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCreditCodeByID(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/creditcode/${id.id}`
      );
      dispatch(slice.actions.getCreditCodeByIDSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUsersByID(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/usersystem/${id.id}`
      );
      dispatch(slice.actions.getUsersByIDSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAdminByID(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/189a1sf898f/${id.id}`
      );
      dispatch(slice.actions.getAdminByIDSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTotalUsers() {
  return async () => {
    try {
      const response = await axiosex.get("https://api.s24hr.com/totalusers");
      dispatch(slice.actions.getTotalUsersSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getTodayUsers() {
  return async () => {
    try {
      const response = await axiosex.get("https://api.s24hr.com/todayusers");
      dispatch(slice.actions.getTodayUsersSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMonthUsers() {
  return async () => {
    try {
      const response = await axiosex.get(
        "https://api.s24hr.com/todayusers//newusermonthly"
      );
      dispatch(slice.actions.getMonthUsersSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUsersListStyle1() {
  return async () => {
    try {
      const response = await axiosex.get("https://api.s24hr.com/usersystem");
      dispatch(slice.actions.getUsersListStyle1Success(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllAdmin() {
  return async () => {
    try {
      const response = await axiosex.get("https://api.s24hr.com/189a1sf898f");
      dispatch(slice.actions.getAllAdminSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAllTransactionDW() {
  return async () => {
    try {
      const response = await axiosex.get("https://api.s24hr.com/8hwe8vhevv");
      dispatch(slice.actions.getAllTransactionDWSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getByIdTransactionDW(id) {
  return async () => {
    try {
      const response = await axiosex.get(
        `https://api.s24hr.com/8hwe8vhevv/transactionbyid/${id}`
      );
      dispatch(slice.actions.getByIdTransactionDWSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
